<template>
  <div>
    <h1>Página em construção</h1>
  </div>
</template>

<script>
export default {
  name: "blank",
  components: {},
  mounted() {
    // this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  computed: {
    route() {
      return this.$route.path;
    }
  }
};
</script>
